import React, { useCallback, useState } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import UiCampaignEditModal from "../../../pages/BaseUi/UiModals/UiCampaignEditModal";
import { TTemplateModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import { CampaignName } from "../../../Components/constants/campaign";
import Loader from "../../../Components/Common/Loader";
import { useOrganizationStore } from "../../../Store/store";
import { Utility } from "../../../utils";
import NoDataLabel from "./NoDataLabel";
import {
  CampaignType,
  EventType
} from "../../../Components/constants/campaign";
import { iconMap } from "./IconMap";

const AutomatedTemplates: React.FC<{
  templates: TTemplateModelJSON[];
  isLoading: boolean;
}> = ({ templates, isLoading }) => {
  const [organization] = useOrganizationStore((state) => [state.organization]);

  const [selectedTemplate, setSelectedTemplate] =
    useState<TTemplateModelJSON>(null);

  const selectTemplate = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      const id = e.currentTarget.dataset.id;
      if (id) {
        setSelectedTemplate(templates.find((item) => item.id === id));
      }
    },
    [setSelectedTemplate, templates]
  );

  const onCloseModal = useCallback(() => {
    setSelectedTemplate(null);
  }, [setSelectedTemplate]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Row>
          {templates.length > 0 &&
            templates.map((template, index) => (
              <Col xl={14} key={index}>
                <Card>
                  <CardBody>
                    <div>
                      <Row>
                        <Col xl={8} lg={8} md={8} sm={12}>
                          <h6 className="mt-0">
                            {iconMap[template.type]} {template.type}
                          </h6>
                          <Row>
                            <p>
                              {Utility.replaceOrganizationCustomAttribute(
                                template.messageBody,
                                organization?.name
                              )}
                            </p>
                          </Row>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6}>
                          <p>Channel</p>
                          <Row>
                            <p>{template.channel}</p>
                          </Row>
                        </Col>

                        <Col xl={2} lg={2} md={2} sm={6}>
                          <p></p>
                          <Row>
                            <p>
                              <Button
                                color="primary"
                                onClick={selectTemplate}
                                data-id={template.id}
                              >
                                Enable Campaign
                              </Button>
                            </p>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          {templates.length === 0 && (
            <NoDataLabel
              title="You've Enabled All Templates!"
              content="All templates are now enabled. There are no templates left to enable at the moment. Stay tuned for new templates coming soon!"
            />
          )}
        </Row>
      )}

      {!!selectedTemplate && (
        <UiCampaignEditModal
          showModal={!!selectedTemplate}
          closeModal={onCloseModal}
          fade={true}
          messageText={selectedTemplate?.messageBody}
          name={selectedTemplate?.type as unknown as CampaignName}
          costPerMessage={selectedTemplate?.costPerMessage}
          type={CampaignType.EVENT}
          event={selectedTemplate?.event as EventType}
        />
      )}
    </>
  );
};

export default AutomatedTemplates;
