import { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";

const FilterDropdown = ({ dropdownItems, toggleTab, title, selectedValue }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      <UncontrolledDropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="mb-2"
      >
        <DropdownToggle color="light">
          {selectedValue || title} <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu>
          {dropdownItems?.length > 0 ? (
            dropdownItems.map((dropdownItem) => (
              <DropdownItem
                key={dropdownItem.id}
                onClick={() => toggleTab(dropdownItem.id, dropdownItem.name)}
              >
                {dropdownItem.name}
              </DropdownItem>
            ))
          ) : (
            <DropdownItem key="no-segment">No Items</DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default FilterDropdown;
