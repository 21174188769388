import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
  Tooltip,
  FormFeedback
} from "reactstrap";
import { createCampaign } from "../../../services";
import {
  CampaignStatus,
  CampaignChannel,
  CampaignType
} from "../../../Components/constants/campaign";
import { ContentState, EditorState } from "draft-js";
import MessageTextArea from "../../../pages/BaseUi/UiModals/MessageTextArea";
import BottomContainer from "../../../pages/BaseUi/UiModals/BottomContainer";
import SMSUtils from "@shoutout-labs/marketbuzz-utils/dist/messages/sms.utils";
import { useAuthStore, useOrganizationStore } from "../../../Store/store";
import { Utility } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import {
  getSegments,
  filterCustomersCount,
  getCustomersCount
} from "../../../services";
import { useMutation } from "@tanstack/react-query";
import { TCampaignCreateRequest } from "@shoutout-labs/market_buzz_crm_types";
import { toast } from "react-toastify";
import {
  PreDefinedSegments,
  isMarketingAllowedRule
} from "../Customers/Constants";
import CustomerSegmentDropdown from "../Customers/CustomerSegmentDropdown";
import { filterConfig } from "../Customers/CustomersFilterConfig";
import { UilInfoCircle } from "@iconscout/react-unicons";
import "./CampaignCreateModal.scss";

interface UiCampaignEditModalProps {
  fade: boolean | undefined;
  showModal: boolean;
  closeModal: () => void;
}

const CampaignCreateModal = ({
  fade,
  showModal,
  closeModal
}: UiCampaignEditModalProps) => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [selectedSegment, setSelectedSegment] = useState("");
  const [selectedSegmentName, setSelectedSegmentName] = useState("");
  const [limitSegments, setLimitSegments] = useState(100);
  const [skipSegments, setSkipSegments] = useState(0);
  const [customersCountOfSegment, setCustomersCountOfSegment] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [isCheckedMarketing, setIsCheckedMarketing] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isSegmentEmpty, setIsSegmentEmpty] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(message))
  );
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  const [organization] = useOrganizationStore((state) => [state.organization]);

  const handleInputChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawText = contentState.getPlainText();
    setMessage(rawText);
    if (rawText.trim()) {
      setMessageError(false);
    } else {
      setMessageError(true);
    }
  };

  const handleCheckboxChange = useCallback(
    (e) => {
      setIsCheckedMarketing(e.target.checked);
    },
    [setIsCheckedMarketing]
  );

  const charCount = useMemo(() => message.length, [message]);

  const pageCount = useMemo(
    () => SMSUtils.getPagesCount(message, 20),
    [message]
  );

  const mutation = useMutation({
    mutationFn: (payload: TCampaignCreateRequest) => {
      return createCampaign(payload);
    },
    onSuccess: () => {
      toast.success("Campaign is created successfully");
      closeModal();
    },
    onError: (error) => {
      toast.error("Campaign creation is failed");
      console.error(error);
    }
  });

  const loadSegmentsData = () => {
    const queryObj = {
      limit: limitSegments,
      skip: skipSegments
    };
    return getSegments(queryObj);
  };

  const getCustomersCountInSegment = useCallback(
    async (filterObj) => {
      const res = await filterCustomersCount({ filterObj });
      setCustomersCountOfSegment(res.count ?? 0);
    },
    [setCustomersCountOfSegment]
  );

  const { data: segmentsData, isLoading } = useQuery<any>({
    queryKey: ["segments", skipSegments],
    queryFn: loadSegmentsData,
    enabled: isLoggedIn(),
    refetchOnWindowFocus: false
  });

  const { data: totalCustomers, isLoading: isLoadingTotalCustomers } =
    useQuery<number>({
      queryKey: [
        "customerCount",
        selectedSegment == "all_customers",
        isCheckedMarketing
      ],
      queryFn: async () => {
        const queryObj = {
          marketingAllowed: isCheckedMarketing,
          isRequiredPhoneNumber: true
        };
        const response = await getCustomersCount(queryObj);
        return response?.count;
      },
      enabled: isLoggedIn,
      refetchOnWindowFocus: false
    });

  const allSegments = useMemo(() => {
    if (!isLoading && segmentsData?.items.length > 0) {
      return [...PreDefinedSegments, ...segmentsData?.items];
    } else {
      return [...PreDefinedSegments];
    }
  }, [segmentsData, isLoading]);

  const onChangeCampaignName = useCallback(
    (e) => {
      setName(e.target.value);
      setIsNameEmpty(!e.target.value.trim());
    },
    [setName, setIsNameEmpty]
  );

  const onSelectSegment = useCallback(
    (segmentId) => {
      setSelectedSegment(segmentId);
      setIsSegmentEmpty(!segmentId);
      if (segmentId === "all_customers") {
        setCustomersCountOfSegment(totalCustomers);
        setSelectedSegmentName("All Customers");
      } else {
        const segmentData = allSegments?.find(
          (segment) => segment?.id === segmentId
        );
        setSelectedSegmentName(segmentData?.name);
        const customerFilters = Utility.getMongoDBQuery(
          segmentData?.filter,
          filterConfig
        );
        getCustomersCountInSegment(customerFilters);
      }
    },
    [
      allSegments,
      totalCustomers,
      getCustomersCountInSegment,
      setSelectedSegment,
      setSelectedSegmentName
    ]
  );

  useEffect(() => {
    if (isCheckedMarketing) {
      if (selectedSegment === "all_customers") {
        setCustomersCountOfSegment(totalCustomers);
      } else {
        const segmentData = allSegments?.find(
          (segment) => segment?.id === selectedSegment
        );

        if (!segmentData.filter) {
          segmentData.filter = {};
        }
        if (!segmentData.filter.children1) {
          segmentData.filter.children1 = [];
        }
        segmentData.filter.children1.push(isMarketingAllowedRule);

        const customerFilters = Utility.getMongoDBQuery(
          segmentData?.filter,
          filterConfig
        );
        getCustomersCountInSegment(customerFilters);
      }
    }
  }, [isCheckedMarketing]);

  const handleCampaignSent = useCallback(() => {
    setIsNameEmpty(!name.trim());
    setIsSegmentEmpty(!selectedSegment);

    if (name.trim() && selectedSegment) {
      let payload: TCampaignCreateRequest = {
        content: message,
        name: name,
        channel: CampaignChannel.SMS,
        status: CampaignStatus.DRAFT,
        type: CampaignType.BROADCAST
      };

      if (isCheckedMarketing) {
        payload.isMarketingAllowed = isCheckedMarketing;
      }
      if (selectedSegment !== "all_customers") {
        const segmentData = allSegments?.find(
          (segment) => segment?.id === selectedSegment
        );
        const customerFilters = Utility.getMongoDBQuery(
          segmentData?.filter,
          filterConfig
        );
        payload.segmentId = selectedSegment;
        payload.filter = customerFilters;
      }
      mutation.mutate(payload);
    } else {
      toast.warn("Ensure that all the required fields are completed.");
    }
  }, [
    name,
    message,
    selectedSegment,
    allSegments,
    mutation,
    isCheckedMarketing
  ]);

  return (
    <div>
      <Modal isOpen={showModal} toggle={closeModal} fade={fade}>
        <ModalHeader toggle={closeModal} color="primary">
          Create New Campaign
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              Campaign Name <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              placeholder="Enter campaign name"
              value={name}
              onChange={onChangeCampaignName}
              invalid={isNameEmpty}
            />
            {isNameEmpty && (
              <FormFeedback>* Campaign name cannot be empty!</FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label>
              Customer Segment <span className="text-danger">*</span>
            </Label>
            <CustomerSegmentDropdown
              allSegments={allSegments}
              toggleTab={onSelectSegment}
              isAllCustomers={true}
              title="Select Customer Segment"
              selectedSegmentName={selectedSegmentName}
            />
            {isSegmentEmpty && (
              <span className="text-danger">
                * Please select a customer segment!
              </span>
            )}
            <FormGroup check inline className="my-2">
              <Input
                type="checkbox"
                checked={isCheckedMarketing}
                onChange={handleCheckboxChange}
              />
              <Label check>
                Send only to customers who have opted in for marketing.
              </Label>
            </FormGroup>
            {selectedSegmentName && (
              <Card className="my-2">
                <CardBody>
                  <p className="my-0 py-0">
                    No. of Customers:
                    <span className="fw-bold"> {customersCountOfSegment}</span>
                    <span className="mx-2">
                      <UilInfoCircle size={15} id="TooltipInfo" />
                      <Tooltip
                        placement="right"
                        isOpen={tooltipOpen}
                        target="TooltipInfo"
                        toggle={toggle}
                        className="custom-tooltip tooltip-inner"
                      >
                        <p className="text-info text-justify">
                          SMS campaigns are sent only to customers who have
                          provided a mobile number.
                        </p>
                      </Tooltip>
                    </span>
                  </p>
                </CardBody>
              </Card>
            )}
          </FormGroup>

          <div className="d-flex justify-content-between align-items-center my-3">
            <Label>
              Message Text <span className="text-danger">*</span>
            </Label>
          </div>
          <MessageTextArea
            editorState={editorState}
            handleInputChange={handleInputChange}
            footerText={organization.shortName ?? `- ${organization.name} -`}
          />
          {messageError && (
            <span className="text-danger">* Message cannot be empty!</span>
          )}
          <BottomContainer
            charCount={charCount}
            pageCount={pageCount}
            message={message}
            isShowCustomAttributeNotification={false}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleCampaignSent}
            disabled={mutation.isPending}
          >
            {mutation.isPending ? "Creating..." : "Start Campaign Now"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={closeModal}
            disabled={mutation.isPending}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CampaignCreateModal;
