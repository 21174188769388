import {
  fetchGet,
  fetchPost,
  fetchPut,
  jsonToQueryParam
} from "./CommonServiceUtils";
import Constants from "../Constants";
import {
  TCampaignGetRequest,
  TCampaignGetResponse,
  TCampaignCreateRequest,
  TCampaignCreateResponse,
  TContentSuggestionRequest,
  TContentSuggestionResponse,
  TCampaignsCountResponse,
  TCampaignUpdateRequest,
  TCampaignUpdateResponse
} from "@shoutout-labs/market_buzz_crm_types";
import GTMService from "./GTMService";

const getCampaigns = (
  queryObj: TCampaignGetRequest
): Promise<TCampaignGetResponse> => {
  return fetchGet(
    `${Constants.REACT_APP_API_BASE_URL}campaigns?${jsonToQueryParam(queryObj)}`
  );
};

const getCampaignsCount = (): Promise<TCampaignsCountResponse> => {
  return fetchGet(`${Constants.REACT_APP_API_BASE_URL}campaigns/count`);
};

const createCampaign = async (
  payload: TCampaignCreateRequest
): Promise<TCampaignCreateResponse> => {
  const res = await fetchPost(
    `${Constants.REACT_APP_API_BASE_URL}campaigns`,
    payload
  );
  // TODO: Test event trigger
  GTMService.campaignCreatedEvent({
    campaignId: res.id,
    campaignName: res.name,
    campaignType: res.type,
    campaignChannel: res.channel,
    campaignCreatedOn: res.createdOn,
    ...(res.actualCost ? { campaignActualCost: res.actualCost } : {})
  });
  return res;
};

const updateCampaign = async (
  payload: TCampaignUpdateRequest,
  campaignId: string
): Promise<TCampaignUpdateResponse> => {
  return fetchPut(
    `${Constants.REACT_APP_API_BASE_URL}campaigns/${campaignId}`,
    payload
  );
};

const regenerateContent = (
  payload: TContentSuggestionRequest
): Promise<TContentSuggestionResponse> => {
  return fetchPost(
    `${Constants.REACT_APP_API_BASE_URL}campaigns/content-suggesion`,
    payload
  );
};

export {
  getCampaigns,
  createCampaign,
  regenerateContent,
  getCampaignsCount,
  updateCampaign
};
