import { Container } from "reactstrap";
import "./NoDataLabel.scss";

const NoDataLabel = ({ title, content }) => {
  document.title = "Automated Campaigns | MarketBuzz";

  return (
    <div className="page-content">
      <Container fluid>
        <div className="noDataLabel px-3 py-3">
          <h6 className="my-2">{title}</h6>
          <p className="my-2">{content}</p>
        </div>
      </Container>
    </div>
  );
};

export default NoDataLabel;
