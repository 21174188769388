import React, { useEffect, useState, useMemo } from "react";
import {
  UilUsersAlt,
  UilChartLine,
  UilRocket,
  UilDashboard
  //UilLightbulbAlt
} from "@iconscout/react-unicons";
import { useOrganizationStore } from "../Store/store";
import { Features } from "../Components/constants/layout";

const Navdata = () => {
  // const [isAiInsights, setIsAiInsights] = useState<boolean>(false);
  const [isCustomers, setIsCustomers] = useState<boolean>(false);
  const [isCampaigns, setIsCampaigns] = useState<boolean>(false);
  const [isReports, setIsReports] = useState<boolean>(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isDashboard, setIsDashboard] = useState<boolean>(true);
  const [organization] = useOrganizationStore((state) => [state.organization]);

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }

  const handleNavTabs = useMemo(() => {
    return () => {
      // if (iscurrentState !== "AiInsights") {
      //   setIsAiInsights(false);
      // }
      if (iscurrentState !== "Dashboard") {
        setIsDashboard(false);
      }
      if (iscurrentState !== "Customers") {
        setIsCustomers(false);
      }
      if (iscurrentState !== "Campaigns") {
        setIsCampaigns(false);
      }
      if (iscurrentState !== "Reports") {
        setIsReports(false);
      }
    };
  }, [
    iscurrentState,
    //isAiInsights,
    setIsCustomers,
    setIsCampaigns,
    setIsReports,
    setIsDashboard
  ]);

  useEffect(() => {
    handleNavTabs();
  }, [handleNavTabs]);

  const menuItems: any = [
    // {
    //   id: "aiInsights",
    //   label: "AI Insights",
    //   icon: <UilLightbulbAlt />,
    //   link: "/#",
    //   stateVariables: isAiInsights,
    //   click: function (e: any) {
    //     e.preventDefault();
    //     setIsAiInsights(!isAiInsights);
    //     setIscurrentState("AiInsights");
    //     updateIconSidebar(e);
    //   }
    // },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: <UilDashboard />,
      link: "/#",
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      }
    },
    {
      id: "customers",
      label: "Customers",
      icon: <UilUsersAlt />,
      link: "/customers",
      click: function (e: any) {
        e.preventDefault();
        setIsCustomers(!isCustomers);
        setIscurrentState("Customers");
        updateIconSidebar(e);
      },
      stateVariables: isCustomers,
      subItems: [
        {
          id: "customerList",
          label: "Customer List",
          link: "/customers",
          parentId: "customers"
        }
        // {
        //   id: "segments",
        //   label: "Segments",
        //   link: "/customers/segments",
        //   parentId: "customers"
        // }
      ]
    },
    {
      id: "campaigns",
      label: "Campaigns",
      icon: <UilRocket />,
      link: "/campaigns",
      click: function (e: any) {
        e.preventDefault();
        setIsCampaigns(!isCampaigns);
        setIscurrentState("Campaigns");
        updateIconSidebar(e);
      },
      stateVariables: isCampaigns,
      subItems: [
        {
          id: "smsCampaigns",
          label: "SMS Campaigns",
          link: "/campaigns/sms",
          parentId: "campaigns"
        },
        {
          id: "automatedCampaigns",
          label: "Automated Campaigns",
          link: "/campaigns/automated",
          parentId: "campaigns"
        }
      ]
    },
    {
      id: "reports",
      label: "Reports",
      icon: <UilChartLine />,
      link: "/reports",
      click: function (e: any) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("Reports");
        updateIconSidebar(e);
      },
      stateVariables: isReports,
      subItems: [
        {
          id: "sales",
          label: "Sales",
          link: "/reports/sales",
          parentId: "reports"
        },
        {
          id: "customers",
          label: "Customers",
          link: "/reports/customers",
          parentId: "reports"
        }
      ]
    }
  ];

  const menuItemsAnalytics: any = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: <UilDashboard />,
      link: "/#",
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      }
    },
    {
      id: "reports",
      label: "Reports",
      icon: <UilChartLine />,
      link: "/reports",
      click: function (e: any) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("Reports");
        updateIconSidebar(e);
      },
      stateVariables: isReports,
      subItems: [
        {
          id: "sales",
          label: "Sales",
          link: "/reports/sales",
          parentId: "reports"
        },
        {
          id: "customers",
          label: "Customers",
          link: "/reports/customers",
          parentId: "reports"
        }
      ]
    }
  ];
  return (
    <React.Fragment>
      {organization.features
        ? organization.features.includes(Features.CRM)
          ? menuItems
          : menuItemsAnalytics
        : menuItems}
    </React.Fragment>
  );
};
export default Navdata;
