import { TOrganizationModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { Container } from "reactstrap";
import AdvanceSearchOption from "../../../Components/Common/AdvanceSearchOption";
import {
  filterOragnizations,
  getConfigurationData,
  searchOragnizations
} from "../../../services";
import FilterDropdown from "./FilterDropdown";
import OrganizationsTable from "./OrganizationsTable";

const SwitchAccount = () => {
  const [isSearchResult, setIsSearchResult] = useState(false);
  const [organizationSearchData, setOrganizationSearchData] = useState<
    TOrganizationModelJSON[]
  >([]);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const [limit, setLimit] = useState<number>(10);
  // eslint-disable-next-line  react-hooks/exhaustive-deps
  const [skip, setSkip] = useState<number>(0);
  const [selectedApplication, setSelectedApplication] = useState("");
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");

  const { data: configData, isLoading } = useQuery({
    queryKey: ["config"],
    queryFn: getConfigurationData
  });

  const searchOrganizations = useCallback(
    async (query: string) => {
      const queryObj = {
        limit,
        skip,
        query: query
      };

      const res = await searchOragnizations(queryObj);

      setIsSearchResult(true);
      setOrganizationSearchData(res.items);
      return res.items;
    },
    [limit, skip]
  );

  const onSelectApplication = useCallback(
    async (applicationId, applicationName) => {
      setSelectedApplication(applicationId);
      setSelectedDropdownValue(applicationName);

      const queryObj = {
        limit,
        skip,
        query: applicationId
      };

      const res = await filterOragnizations(queryObj);
      setIsSearchResult(true);
      setOrganizationSearchData(res.items);
    },
    [setSelectedApplication, setSelectedDropdownValue, limit, skip]
  );

  const dropdownItems = useMemo(() => {
    return Object.entries(configData?.clover?.apps || {}).map(([id, app]) => ({
      id,
      name: app.appName
    }));
  }, [configData?.clover?.apps]);

  return (
    <div className="page-content d-flex justify-content-center align-items-center">
      <Container>
        <h1>Switch account</h1>
        <div className="d-flex justify-content-between">
          <AdvanceSearchOption handleSearch={searchOrganizations} />
          <FilterDropdown
            dropdownItems={dropdownItems || []}
            toggleTab={(id, name) => onSelectApplication(id, name)}
            title="Select Application"
            selectedValue={selectedDropdownValue}
          />
        </div>
        <OrganizationsTable
          searchResults={organizationSearchData as []}
          isSearchResult={isSearchResult}
        />
      </Container>
    </div>
  );
};

export default SwitchAccount;
