import { useState, useMemo } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import AutomatedCampaignCard from "./AutomatedCampaignCard";
import AutomatedTemplates from "./AutomatedTemplates";
import { useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../../Store/store";
import { CampaignType } from "../../../Components/constants/campaign";
import { getTemplates, getCampaigns } from "../../../services";

const AutomatedCampaignPage = () => {
  document.title = "Automated Campaigns | MarketBuzz";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limitTemplate, setLimitTemplate] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skipTemplate, setSkipTemplate] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [limitCampaign, setLimitCampaign] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skipCampaign, setSkipCampaign] = useState<number>(0);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);

  const { data: templates, isLoading: isLoadingTemplates } = useQuery({
    queryKey: ["templates", skipTemplate, CampaignType],
    queryFn: async () => {
      const queryObj = {
        limit: limitTemplate,
        skip: skipTemplate,
        campaignType: CampaignType.EVENT
      };
      const response = await getTemplates(queryObj);
      return response.items;
    },
    enabled: isLoggedIn,
    refetchOnWindowFocus: false
  });

  const { data: campaigns, isLoading: isLoadingCampaigns } = useQuery({
    queryKey: ["campaigns", skipCampaign, CampaignType],
    queryFn: async () => {
      const queryObj = {
        limit: limitCampaign,
        skip: skipCampaign,
        type: CampaignType.EVENT
      };
      const response = await getCampaigns(queryObj);
      return response.items;
    },
    enabled: isLoggedIn,
    refetchOnWindowFocus: false
  });

  const templatesNotInCampaigns = useMemo(() => {
    if (!campaigns) return templates;
    if (!templates) return [];

    const campaignEvents = new Set(
      campaigns.map((campaign: any) => campaign.event)
    );
    return templates.filter(
      (template: any) => !campaignEvents.has(template.event)
    );
  }, [templates, campaigns]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Automated Campaigns" pageTitle="Campaigns" />
        <Row className="project-wrapper">
          <h5>Campaigns</h5>
          <AutomatedCampaignCard
            campaigns={campaigns}
            isLoading={isLoadingCampaigns}
          />
        </Row>
        <Row className="project-wrapper">
          <h5>Templates</h5>
          <AutomatedTemplates
            templates={templatesNotInCampaigns}
            isLoading={isLoadingTemplates}
          />
        </Row>
      </Container>
    </div>
  );
};

export default AutomatedCampaignPage;
