import { useCallback, useMemo, useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  createCampaign,
  regenerateContent,
  updateCampaign
} from "../../../services";
import {
  CampaignStatus,
  CampaignChannel,
  CampaignType,
  CampaignName,
  EventType
} from "../../../Components/constants/campaign";
import ContentRegenerationIcon from "../../../../src/assets/icons/contentGeneration";
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  EditorState
} from "draft-js";
import MessageTextArea from "./MessageTextArea";
import BottomContainer from "./BottomContainer";
import SMSUtils from "@shoutout-labs/marketbuzz-utils/dist/messages/sms.utils";
import { useCustomersStore, useOrganizationStore } from "../../../Store/store";
import { Utility } from "../../../utils";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  TCampaignCreateRequest,
  TCampaignUpdateRequest
} from "@shoutout-labs/market_buzz_crm_types";
import { toast } from "react-toastify";
import "./UiCampaignEditModal.scss";

interface UiCampaignEditModalProps {
  fade: boolean | undefined;
  messageText: string;
  name: CampaignName;
  toggleTab?: (tab: string) => void;
  costPerMessage?: number;
  showModal: boolean;
  closeModal: () => void;
  type?: CampaignType;
  event?: EventType;
  isEditCampaign?: boolean;
  campaignId?: string;
}

const UiCampaignEditModal = ({
  fade,
  messageText,
  name,
  toggleTab,
  costPerMessage,
  showModal,
  closeModal,
  type,
  event,
  isEditCampaign,
  campaignId
}: UiCampaignEditModalProps) => {
  const [message, setMessage] = useState(messageText);
  const [credits, organization] = useOrganizationStore((state) => [
    state.credits,
    state.organization
  ]);
  const [totalCustomers] = useCustomersStore((state) => [state.totalCustomers]);

  const queryClient = useQueryClient();

  const findCustomAttributeDecorator = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ): void => {
    const text = contentBlock.getText();
    const customAttributes = Utility.findCustomAttributes(text);
    customAttributes.forEach((customAttribute) => {
      const start = text.indexOf(`[${customAttribute}]`);
      if (start !== -1) {
        callback(start, start + `[${customAttribute}]`.length);
      }
    });
  };

  const decorator = useMemo(() => {
    return new CompositeDecorator([
      {
        strategy: findCustomAttributeDecorator,
        component: (props) => (
          <span style={{ color: "#0d6efd" }}>{props.children}</span>
        )
      }
    ]);
  }, []);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(messageText),
      decorator
    )
  );

  const handleInputChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawText = contentState.getPlainText();
    setMessage(rawText);
  };

  const charCount = useMemo(() => message.length, [message]);

  const pageCount = useMemo(
    () => SMSUtils.getPagesCount(message, 20),
    [message]
  );

  const mutation = useMutation({
    mutationFn: (payload: TCampaignCreateRequest) => {
      return createCampaign(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns"] });
      toast.success("Campaign is created");
      closeModal();
      toggleTab("2");
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const mutationUpdateCampaign = useMutation({
    mutationFn: (params: {
      payload: TCampaignUpdateRequest;
      campaignId: string;
    }) => {
      return updateCampaign(params.payload, params.campaignId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns"] });
      toast.success("Campaign is updated");
      closeModal();
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleCampaignSent = useCallback(async () => {
    if (isEditCampaign) {
      const updatePayload = {
        content: message,
        name: name
      };
      mutationUpdateCampaign.mutate({ payload: updatePayload, campaignId });
    } else {
      let payload = {
        content: message,
        name: name,
        channel: CampaignChannel.SMS,
        status: CampaignStatus.DRAFT,
        type: type
      } as TCampaignCreateRequest;

      if (type === CampaignType.EVENT) {
        payload.event = event;
      }
      mutation.mutate(payload);
    }
  }, [message, name, mutation]);

  const handleRegenerateContent = useCallback(async () => {
    try {
      const payload = {
        content: message
      };
      const regeneratedPayload = await regenerateContent(payload);
      setMessage(regeneratedPayload.message?.content);
      const newEditorState = EditorState.createWithContent(
        ContentState.createFromText(regeneratedPayload.message?.content),
        decorator
      );
      setEditorState(newEditorState);
    } catch (e) {
      console.error(e);
    }
  }, [decorator, message]);

  const estimatedBuzzCredits = useMemo(() => {
    return costPerMessage * totalCustomers;
  }, [costPerMessage, totalCustomers]);

  useEffect(() => {
    const updatedMessage = Utility.replaceOrganizationCustomAttribute(
      messageText,
      organization?.name
    );
    setMessage(updatedMessage);
    const newEditorState = EditorState.createWithContent(
      ContentState.createFromText(updatedMessage),
      decorator
    );
    setEditorState(newEditorState);
  }, [organization, messageText, decorator]);

  return (
    <div>
      <div>
        <Modal isOpen={showModal} toggle={closeModal} fade={fade}>
          <ModalHeader toggle={closeModal} color="primary">
            {isEditCampaign ? name : "Start Campaign"}
          </ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0">Message Text</p>
              <Button
                className="btn-sm"
                style={{ backgroundColor: "#8772f9", borderColor: "#8772f9" }}
                onClick={handleRegenerateContent}
              >
                <ContentRegenerationIcon />
                <span style={{ marginLeft: "5px" }}>Generate Content</span>
              </Button>
            </div>
            <MessageTextArea
              editorState={editorState}
              handleInputChange={handleInputChange}
              footerText={organization.shortName ?? `- ${organization.name} -`}
            />
            <BottomContainer
              charCount={charCount}
              pageCount={pageCount}
              message={message}
              isShowCustomAttributeNotification={true}
            />
            {credits < estimatedBuzzCredits && (
              <p className="fst-italic text-danger">
                Insufficient Buzz credits. Please buy more credits to send this
                campaign.
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleCampaignSent}
              disabled={
                Utility.hasCustomAttributes(message) ||
                pageCount > 3 ||
                credits < estimatedBuzzCredits ||
                mutation.isPending
              }
            >
              {!isEditCampaign
                ? mutation.isPending
                  ? "Creating..."
                  : "Start Campaign Now"
                : "Edit Template"}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={closeModal}
              disabled={mutation.isPending || mutationUpdateCampaign.isPending}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default UiCampaignEditModal;
