import React, { useState } from "react";
import { Editor, EditorState } from "draft-js";
import { Button } from "reactstrap";
import { updateOrganizationName } from "../../../services";
import { toast } from "react-toastify";

interface MessageTextAreaProps {
  editorState: EditorState;
  handleInputChange: (newEditorState: EditorState) => void;
  footerText: string;
  onFooterTextChange?: (newFooterText: string) => void;
}

const MessageTextArea = ({
  editorState,
  handleInputChange,
  footerText,
  onFooterTextChange
}: MessageTextAreaProps) => {
  const [footerName, setFooterName] = useState(footerText);
  const [error, setError] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const handleFooterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setFooterName(value);
    setIsEditing(true);
    if (value.length < 5) {
      setError("Name must be at least 3 characters long");
    } else {
      setError("");
    }
  };

  const handleSave = async () => {
    try {
      const payload = { shortName: footerName };
      await updateOrganizationName(payload);
      setIsEditing(false);
      toast.success("Short name saved successfully.");
    } catch (error) {
      console.error("Failed to save short name:", error);
    }
  };

  return (
    <div
      style={{
        minHeight: "150px",
        border: "1px solid #ddd",
        cursor: "text",
        paddingTop: "2px",
        borderRadius: "2px",
        boxShadow: "inset 0px 1px 8px -3px #ABABAB",
        position: "relative"
      }}
    >
      <Editor editorState={editorState} onChange={handleInputChange} />

      <div
        style={{
          border: "1px solid #ddd",
          marginTop: "90px",
          padding: "4px 0 8px 8px",
          boxSizing: "border-box"
        }}
      >
        <input
          type="text"
          value={footerName}
          onChange={handleFooterChange}
          style={{
            border: "none",
            outline: "none",
            fontSize: "14px",
            background: "transparent",
            width: "100%"
          }}
        />
        {isEditing && (
          <Button
            onClick={handleSave}
            disabled={!!error || footerName.length < 5}
            color="primary"
            className="btn-sm mx-3"
          >
            Save
          </Button>
        )}
        {error && (
          <div style={{ color: "red", fontSize: "12px", marginTop: "4px" }}>
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageTextArea;
